import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Spline from '@splinetool/react-spline';

import Button from '../components/Button/index.jsx'
import Section from "../components/Section/index.jsx";
import Typography from "../components/Typography/index.jsx";

import ReactPlayer from 'react-player'
import { doWeHaveHardwareAcc } from "../shared/doWeHaveHardwareAcc.js";


const SSection = styled(Section)`
  padding-bottom: 80px;
  padding-top: 0;

  h2 {
    font-size: 40px;
  }


  @media only screen and (min-width: 600px){
    padding-bottom: 0px;
    .Content {
      display: flex;
      flex-direction: row-reverse;
      max-width: 100%;
      text-align: left;
      margin: unset;
      float: right;
      div {
        text-align: left;
      }
    }
    h2 {
      font-size: 60px;
    }
  }

  @media only screen and (max-width: 400px){
    h2 {
      font-size: 30px;
    }
  }

  @media only screen and (min-width: 1098px) {
    .Content {
      max-width: calc( 100% - ( ( 100% - 1098px ) / 2 ) );
    }
  }

`;

const VideoBackgroundContainer = styled.div`
  position: relative;
  width: calc(100vw);
  height: 80vw;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 32px;
  border-radius: 0;
  background-image: url(./placeholder/LtfWiOmg1OxayO1gqV4rD9u9LAA.mp4_snapshot_00.01.226.webp);
  background-position: right;
  background-size: cover;
  @media only screen and (min-width: 500px){
    height: calc(100vw - 32px);
  }
  @media only screen and (min-width: 600px){
    min-height: 650px;
    height: calc(100vh - 68px);

    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    margin-right: -16px;
  }
  div:first-of-type {
    height: 100% !important;
    width: 100% !important;
    display: flex;
    video {
      display: block;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
  .spline {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    @media only screen and (max-width: 600px){
      width: 200% !important;
      height: 200% !important;
      scale: 0.5;
      margin-top: -45%;
      margin-left: -50%;
    }
    @media only screen and (max-width: 500px){
      margin-top: -40%;
    }
  }
`;

const Subtext = styled(Typography)`
  max-width: 640px;
  margin-top: ${(props) => props.marginTop && `${props.marginTop}px`};
  .mb32 {
    margin-bottom: 32px
  }
`;

const TextContainer = styled.div`
  @media only screen and (min-width: 600px){
    padding-right: 32px;
  }
`;


const HOPRShield = styled.img`
  position: absolute;
  bottom: 8px;
  right: 16px;
  max-width: 55px;
  @media only screen and (min-width: 400px){
    max-width: 85px;
  }
  @media only screen and (min-width: 600px){
    max-width: 110px;
    right: 8px;
  }
`

const SplineReplacement = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  object-position: center;
`


function No(props) {
  const [weHaveHardwareAcc, set_weHaveHardwareAcc] = useState(false);

  // useEffect(() => {
  //   const tmp = doWeHaveHardwareAcc();
  //   set_weHaveHardwareAcc(tmp)
  // }, []);

  return (
    <SSection
      id={"NO-PAGE"}
      center
    // gradient
    >
      <VideoBackgroundContainer
        className="VideoBackgroundContainer"
      >
        <ReactPlayer
          className="VideoBackground"
          url='videos/LtfWiOmg1OxayO1gqV4rD9u9LAA.mp4'
          playing
          loop
          muted
        />

        {
          weHaveHardwareAcc ?
            <Spline
              className="spline"
              scene="https://prod.spline.design/8KpG2dYYMmyY4CpS/scene.splinecode"
            />
            :
            <SplineReplacement
              src="./gnosisvpn/gnosis-vpn-cube.png"
            />
        }

        <HOPRShield
          src='/images/hopr_data_privacy_blue.svg'
        />
      </VideoBackgroundContainer>


      <TextContainer>
        <Typography type="h2">
          THIS IS NOT THE PAGE YOU ARE LOOKING FOR
        </Typography>
        <br/>
        <Subtext center mb32>
          To manage load and aid with testing,{" "}<a
            href='https://gnosisvpn.com/servers#allowlist'
            style={{
              width: 'unset',
              maxWidth: 'unset',
              height: 'unset',
              lineHeight: '28px',
              marginTop: '16px'
            }}
          >
            an allow list of sites
          </a>{" "}is currently enforced.
        </Subtext>
      </TextContainer>
    </SSection>
  );
}

export default No;
