import React, { useEffect, useState } from "react";
import './App.css';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router";

import Layout from './components/Layout/index.jsx'

import Section0 from './sections/Section0.jsx'
import Section1B from './sections/Section1B.jsx'
import Section1 from './sections/Section1.jsx'
import Section2 from './sections/Section2.jsx'
import Section2B from './sections/Section2B.jsx'
import Section3 from './sections/Section3.jsx'
import Section4 from './sections/Section4.jsx'
import Section5 from './sections/Section5.jsx'
import Section6 from './sections/Section6.jsx'
import Section7 from './sections/Section7.jsx'
// import Section8 from './sections/Section8.jsx'
import Section9 from './sections/Section9.jsx'
import Section10 from './sections/Section10.jsx'
import Section11 from './sections/Section11.jsx'
import Section13 from './sections/Section13.jsx'
import Section14 from './sections/Section14.jsx'

import Servers from "./subpages/Servers.jsx";
import Relayers from "./subpages/Relayers.jsx";
import No from "./subpages/No.jsx";

function App() {
  const noLocation = window && window.location.host === "no.gnosisvpn.com";

  return (
    <div className="App">
      <BrowserRouter>
        <Layout >
          <Routes>
            {
              noLocation ?
                <Route path="/*" element={<No />} />
                :
                <>
                  <Route path="/servers" element={<Servers />} />
                  <Route path="/no" element={<No />} />
                  {/* <Route path="/relayers" element={<Relayers />} /> */}
                  <Route path="/*" element={<Website />} />
                </>
            }
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>

  );
}

function Website() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{
    if(location.pathname !== '/') {
      navigate('/')
    }
  },[location])

  return <>
    <Section0 />
    <Section1 />
    <Section1B />
    <Section2 />
    <Section2B />
    {/* <Section3 /> */}
    <Section4 />
    <Section5 />
    <Section6 />
    <Section7 />
    {/* <Section8/> */}
    {/* <Section9 /> */}
    <Section10 />
    {/* <Section11 /> */}
    {/* <Section12/> */}
    <Section13 />
    <Section14 />
  </>
}

export default App;
